import React from 'react'
import { Grid } from '@primer/components'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import PageSetup from '../components/layout/PageSetup'
import Card from '../components/reusable/Card'
import CovertArt from '../components/reusable/CovertArt'

// Fading out siblings on hover effect from https://www.trysmudford.com/blog/fade-out-siblings-css-trick/
const MusicGridContainer = styled(Grid)`
  pointer-events: none;
  & > * {
    pointer-events: auto;
  }

  :hover > *,
  :focus-within > * {
    filter: grayscale(1);
  }

  :hover > :focus:not(:hover) {
    filter: grayscale(1);
  }

  :hover > *:hover,
  :focus-within > :focus {
    filter: grayscale(0);
  }
`

const MusicPage = () => (
  <StaticQuery
    query={graphql`
      query MusicPageQuery {
        allMusicYaml {
          nodes {
            title
            imgName
            release
            genre
            fields {
              slugPath
            }
          }
        }
      }
    `}
    render={data => {
      const songs = data.allMusicYaml.nodes.sort(
        (songA, songB) => new Date(songB.release) - new Date(songA.release)
      )
      const CoverArtImage = ({ imgName, title }) => (
        <CovertArt imgName={imgName} title={title} />
      )
      const gridItems = songs.map((song, i) => {
        return (
          <Card
            imageContent={
              <CoverArtImage
                imgName={song.imgName}
                title={song.title}
              />
            }
            title={song.title}
            subtitle={song.genre}
            path={song.fields.slugPath}
            key={song.title + i}
          />
        )
      })

      return (
        <PageSetup pageTitle="Music" darkerBackground>
          <MusicGridContainer
            gridTemplateColumns={[
              '1fr',
              '1fr',
              '1fr 1fr',
              '1fr 1fr',
              '1fr 1fr 1fr',
            ]}
            gridGap="6"
            mt={10}
            mb={12}
          >
            {gridItems}
          </MusicGridContainer>
        </PageSetup>
      )
    }}
  />
)

export default MusicPage
